import { Canvas } from "@react-three/fiber"
import { useEffect, useRef, useState } from "react"
import AvatarManager from "./AvatarManager"
import { OrbitControls } from "@react-three/drei"
import FaceLandmarkManager from "./FaceLandmarkManager"
import * as THREE from "three"
import { FaceLandmarkerResult } from "@mediapipe/tasks-vision"

export interface RemoteLandMark {
  [key: string]: FaceLandmarkerResult
}

interface AvatarCanvasProps {
  width: number
  height: number
  url: string
  remoteLandMarks: RemoteLandMark
  remoteId?: string
}

const AvatarCanvas = ({
  width,
  height,
  url,
  remoteLandMarks,
  remoteId,
}: AvatarCanvasProps) => {
  const [scene, setScene] = useState<THREE.Scene | null>()
  const [isLoading, setIsLoading] = useState(true)
  const avatarManagerRef = useRef<AvatarManager>()
  const requestRef = useRef(0)
  const isFirstRender = useRef(true)

  const animate = () => {
    let results = FaceLandmarkManager.getInstance().getResults()

    if (remoteId && remoteLandMarks[remoteId]) {
      results = remoteLandMarks[remoteId]
    }

    avatarManagerRef.current?.updateFacialTransforms(results, false)
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, [])

  useEffect(() => {
    if (!isFirstRender.current) {
      return
    }

    isFirstRender.current = false
    setIsLoading(true)
    const avatarManager = new AvatarManager()
    avatarManagerRef.current = avatarManager
    avatarManager
      .loadModel(url)
      .then(() => {
        setScene(avatarManager.getScene())
        setIsLoading(false)
      })
      .catch((e: any) => {
        alert(e)
      })
  }, [url])

  return (
    <div className="absolute" style={{ width: width, height: height }}>
      <Canvas camera={{ fov: 30, position: [0, 0.5, 1] }}>
        <ambientLight />
        <directionalLight position={[10, 15, 5]} intensity={1.5} />
        <OrbitControls
          target={[0, 0.6, 0]}
          enableDamping={false}
          enableRotate={false}
          enableZoom={false}
          enablePan={false}
        />
        {scene && <primitive object={scene} />}
      </Canvas>
    </div>
  )
}

export default AvatarCanvas
