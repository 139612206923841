import * as livekit from "livekit-client"
import { ConnectionState, Room, RoomEvent, Track } from "livekit-client"
import { useEffect, useRef, useState } from "react"
import Webcam from "react-webcam"
import "./App.css"
import reactLogo from "./assets/react.svg"
import AvatarCanvas, { RemoteLandMark } from "./AvatarCanvas"
import FaceLandmarkManager from "./FaceLandmarkManager"
import viteLogo from "/vite.svg"

const room = new livekit.Room({})

function App() {
  const isFirstRender = useRef(true)
  const [count, setCount] = useState(0)
  const webcamRef = useRef<Webcam>(null)
  const audioTrackHolder = useRef<HTMLDivElement>(null)
  const remoteLandMarks = useRef<RemoteLandMark>({})

  useEffect(() => {
    if (!isFirstRender.current) {
      return
    }

    isFirstRender.current = false

    const desiredFPS = 30 // Target FPS
    const frameInterval = 1000 / desiredFPS

    const faceLandmarkManager = FaceLandmarkManager.getInstance()

    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      const detect = async () => {
        try {
          if (webcamRef.current!.video?.paused) {
            throw new Error("video is paused")
          }

          if (
            webcamRef.current!.video!.width > 0 &&
            webcamRef.current!.video!.height > 0
          ) {
            const result = faceLandmarkManager.detectLandmarks(
              webcamRef.current!.video!,
              Date.now()
            )

            if (room.state === ConnectionState.Connected) {
              if (room.remoteParticipants.size > 0) {
                room.localParticipant.publishData(
                  new TextEncoder().encode(
                    JSON.stringify({
                      id: room.localParticipant.identity,
                      landmark: result,
                    })
                  )
                )
              }
            }
          }
        } catch (e) {
          console.error(e)
        }

        setTimeout(detect, frameInterval)
      }
      setTimeout(detect, 3000)
      // detect()
    }
  }, [])

  const connect = async () => {
    const devices = await Room.getLocalDevices("audioinput")
    console.log(devices)
    room
      .on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
        console.log(track)
        if (track.kind === Track.Kind.Audio) {
          const element = track.attach()
          console.log(track.mediaStream)
          console.log(element)
          audioTrackHolder.current?.appendChild(element)
        }
      })
      .on(RoomEvent.ParticipantConnected, (participant) => {
        console.log(participant)
        setCount(count + 1)
      })
      .on(RoomEvent.ParticipantDisconnected, (participant) => {
        console.log(participant)
        setCount(count - 1)
      })
      .on(RoomEvent.DataReceived, (data) => {
        const string = new TextDecoder().decode(data)
        const dataJson = JSON.parse(string)
        const id = dataJson.id
        const landmark = dataJson.landmark

        remoteLandMarks.current[id] = landmark

        // console.log("recerved from " + id, landmark)
      })
      .on(RoomEvent.TrackUnsubscribed, (track, publication, participant) => {
        track.detach()
        console.log("unsubscribed")
      })
      .on(RoomEvent.TrackPublished, (track, publication) => {
        console.log(track)
        console.log(publication)
      })
      .on(RoomEvent.Connected, () => {
        console.log("connected")
        setCount(room.remoteParticipants.size)
      })
      .on(RoomEvent.AudioPlaybackStatusChanged, () => {
        console.log(room.canPlaybackAudio)
      })

    const token = await fetch("https://api.okparty.justporr.dev/token").then(
      (r) => r.text()
    )

    console.log(token)

    await room.connect("https://livekit.okparty.justporr.dev", token)
    console.log("publishing")
    const p = room.localParticipant
    await p.setMicrophoneEnabled(true)
    console.log("published")
  }

  const remoteMap = [...room.remoteParticipants.keys()].sort()

  return (
    <>
      <div>
        <Webcam
          width={640}
          height={480}
          ref={webcamRef}
          style={{
            opacity: 0.00000001,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -999999,
          }}
        />
        <AvatarCanvas
          width={webcamRef.current?.video?.width || 640}
          height={webcamRef.current?.video?.height || 480}
          remoteLandMarks={remoteLandMarks.current}
          url={
            "https://models.readyplayer.me/6460691aa35b2e5b7106734d.glb?morphTargets=ARKit"
          }
        />
        {/* <div style={{ marginTop: 10 }}>
          <AvatarCanvas
            width={webcamRef.current?.video?.width || 640}
            height={webcamRef.current?.video?.height || 480}
            remoteLandMarks={remoteLandMarks.current}
            url={
              "https://models.readyplayer.me/6460691aa35b2e5b7106734d.glb?morphTargets=ARKit"
            }
          />
        </div> */}

        {remoteMap.map((id) => (
          <AvatarCanvas
            key={id}
            width={640 / 2}
            height={480 / 2}
            remoteLandMarks={remoteLandMarks.current}
            remoteId={id}
            url={
              "https://models.readyplayer.me/6460691aa35b2e5b7106734d.glb?morphTargets=ARKit"
            }
          />
        ))}
        <p>Number of participants: {room.remoteParticipants.size}</p>
        <a href="https://vitejs.dev" target="_blank">
          <img src={viteLogo} className="logo" alt="Vite logo" />
        </a>
        <a href="https://react.dev" target="_blank">
          <img src={reactLogo} className="logo react" alt="React logo" />
        </a>
      </div>
      <h1>Vite + React</h1>
      <div className="card">
        <button onClick={() => setCount((count) => count + 1)}>
          count is {count}
        </button>
        <p>
          Edit <code>src/App.tsx</code> and save to test HMR
        </p>
      </div>
      <p className="read-the-docs">
        Click on the Vite and React logos to learn more
      </p>
      <button
        onClick={() => {
          connect()
        }}
      >
        Connect
      </button>
      <button onClick={() => room.disconnect()}>Disconnect</button>
      <div ref={audioTrackHolder}></div>
    </>
  )
}

export default App
